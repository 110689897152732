.each-slide-effect > div {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: no-repeat center center fixed;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.FloatingMessage {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 99;
  position: absolute;
  height: 200px;
  width: 500px;
  background-color: rgba(37, 82, 106, 0.8);
  border-radius: 10px;
  transform: translate(-50%, 0);
}

.staticMessage {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 99;
  height: 200px;
  width: 500px;
  background-color: rgba(37, 82, 106, 0.8);
  border-radius: 10px;
  max-width: 90%;
}

.FloatingMessage h1,
.staticMessage h1 {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
  color: rgba(223, 223, 223, 0.8);
}
.FloatingMessage /* .staticMessage */ img {
  opacity: 0.8;
}

/* .logo {
  position: absolute;
  top: 10%;
  left: 50%;
  height: 15%;
  transform: translate(-50%, 0);
  z-index: 9999;
} */
/* .logo img {
  height: 100%;
} */
